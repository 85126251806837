<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Header @scrollToSection="scrollToSection"/>
        <router-view @postNickname="postNickname" :currency="currency" @login="login" @forgotPass="forgotPass" @registration="registration" @sendContactForm="sendContactForm" :isAuth="isAuth" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @chooseCategoty="chooseCategoty" :activeCategory="activeCategory" @orderSubmit="orderSubmit"/>
        <Footer :settings="settings" />
        <transition name="fade">
          <Login 
            v-if="signInModalIsVisible" 
            @login="login" 
            @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <Registration 
            v-if="signUpModalIsVisible" 
            @registration="registration" 
          />
        </transition>
        <!-- <div class="modal modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">Cookies In Use</div>
            <div class="desc chekbox-label">
              Welcome to MineWander! We employ cookies and similar technologies to enhance the functionality of our website, ensuring a seamless and personalized browsing experience for you. Some cookies, crucial for basic functionality, are set automatically without requiring explicit consent. Alongside essential cookies, we may introduce optional cookies and similar technologies that provide various benefits, enriching your interaction with our website. Since these are non-essential, their usage depends on your preferences. For more detailed information, please refer to our <a class="title" @click="goToPage('cookie')">Cookie Policy</a>.
            </div>
            <button class="button blue" @click="acceptCookies">Got it</button>
          </div>
        </div> -->
        <cookie-modal :textPageList="textPageList"></cookie-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import {scroller} from 'vue-scrollto/src/scrollTo'
import {loadScriptsFromString} from "@/utils/load_scripts";
import Login from './components/Login.vue'
import Registration from './components/Registration.vue'
import CookieModal from './components/CookieModal.vue';

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Menu,
    Login,
    Registration,
    CookieModal
  },
  data: function() {
    return {
      hostname: '',
      cookesModalIsVisible: false,
      settings: {},
      nav: [
        {
          title: 'Main',
          path: '/'
        },
        {
          title: 'Rules',
          path: '/rules'
        },
        {
          title: 'Faq',
          path: '/faq'
        },
        {
          title: 'How it works',
          scrollSection: '#howItWorks'
        },
        {
          title: 'Shop',
          path: '/categories'
        },
      ],
     pageLoaded: false,
     menuVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      textPageList: [],
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      orderHistory: '',
      imgDomain: '',
      addToCartChosenItem: '',
      currency: '',
      cartContents: [],
      totalSum: '0.00',
      categories: [],
      activeCategory: '',
      socialLinks: [],
      faq: '',
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      ip: '',
      port: ''

    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
      this.clearError();
    }
  },
  methods: {
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
        this.faq = res.data.payload
      })
      .catch(() => {
        
      })
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i];
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem.title.replace(/\//g, '').replace(/ /g, '-'), params: {data: newItem.title.replace(/\//g, '').replace(/ /g, '-')}})

      this.getTextPageContent(newItem.id);
      this.closeSignUp();
    },
    getTextPageContent(id) {
      let newId = '';
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';

      if (/-/.test(id)) {
        this.$http.get(process.env.VUE_APP_API + 'static-pages')
        .then((res) => {
          newId = res.data.payload.filter(item => item.title.replace(/\//g, '').replace(/ /g, '-') == id)[0].id;
          this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
          .then((res) => {
            this.textPageTitle = res.data.payload.title;
            this.textPageContent = res.data.payload.content;
            this.textPageHeader = res.data.payload.header;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal()
            }
          })
        })
      } else {
        newId = id;
        this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignInModal()
          }
        })
      }

      
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch((res) => {
         if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    postNickname(nickname) {
      let data = {
        "nickname": nickname
      }
      this.$http.post(process.env.VUE_APP_API + 'set-nickname', data)
      .then((res) => {
        console.log(res)
        if (res.status == '200') {
          this.$router.push({ path: '/categories' })
        }
      })
      .catch((res) => {
          console.log(res)
          this.error = res.response.data.message
      })
    },
    getSettings() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        this.ip = res.data.payload.game_server.host;
        this.port = res.data.payload.game_server.port;

        const currentSettings = self.settings;
        self.settings = res.data.payload;

        if (!currentSettings || !Object.keys(currentSettings).length)
        {
          const snippet = res.data.payload.google_tag;
          if (snippet) {
            loadScriptsFromString(snippet);
          }
        }
      })
      .catch(() => {
      });
    },
    clearError() {
      this.error = '';
    },
    login(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.isAuth = true;
          this.clearError();
          this.getOrderHistory();
          this.getCartContents();
          this.closeSignIn();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            self.getCartContents();
            this.closeSignUp();
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
        this.$router.push({path: '/'});
      })
      .catch((res) => {
        if(res.response.status === 401) {
          console.log(res);
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch(() => {
        
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    
    getSocialLinks() {
      return this.$http.get(process.env.VUE_APP_API + 'social-links')
          .then((res) => {
            this.socialLinks = res.data.payload;
          })
          .catch(() => {});
    },
    closeSignIn() {
      this.error = '';
      this.signInModalIsVisible = false;
      document.body.style.overflow = 'auto';
    },
    closeSignUp() {
      this.signUpModalIsVisible = false;
      document.body.style.overflow = 'auto';
    },
    openSignIn() {
      this.error = '';
      document.body.style.overflow = 'hidden';
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    openSignUp() {
      this.error = '';
      document.body.style.overflow = 'hidden';
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
    },
    sendContactForm(data) {
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          this.successMessage = 'Thank you your message has been sent';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch(() => {
       
      })
      
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch(() => {
        
      })
    },
    addToCart(item, id) {
      if (!this.isAuth) {
         this.openSignIn();
      } else {
        let self = this;

        let cartItem = {
          'id': id,
          'count': 1
        }

        for(var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
              let cartItemCount = this.cartContents[i].count;
              cartItem = {
                'id': id,
                'count': cartItemCount + 1
              }
              break;
          }
        }

        this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
        .then((res) => {
          this.getCartContents();
          self.addToCartChosenItem = cartItem.id;
          self.cartContents = res.data.cart
          setTimeout(()=> {
            self.addToCartChosenItem = '';
          }, 2000)
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn();
          }
        })
      }
    },
    emptyCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
      .then((res) => {
        if (res.data.status == "OK") {
          this.getCartContents();
        }
      })
      .catch(() => {
       
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {

        console.log(res.data.cart)
          this.cartContents = res.data.cart;
          
          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
      })
      .catch(() => {
        
      })
    },
    scrollToSection(path) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo(path)
      }, 200)
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    chooseCategoty(item) {
      this.activeCategory = item;
      this.$router.push({ path: '/products' + item.url })
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload;
        for (const obj of this.categories) {
          if (obj.url.replace(/\//g, '') == this.$route.params.url) {
            this.activeCategory = obj;
            break;
          }
        }
      })
      .catch(() => {
        
      })
    },
    orderSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
        window.location = res.data.redirect_url;
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
  },
  mounted: function() {
    this.hostname = this.getDomainName(window.location.hostname);
    this.getTextPages();
    this.getSettings();
    this.getCategories();
    this.getSocialLinks();
    this.cookiesModal();
    this.getFaq();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;

    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getCartContents();
        }
    })
    .catch(() => {
    })
    
    

    
  },
  computed: {
    
  }
}
</script>
